import { Link } from '@remix-run/react';

import {
  matchaEssentialsListRoute,
  privacyRoute,
  supportRoute,
  termsRoute,
} from '@matchastock/routes';
import {
  buildUtmAbsoluteHref,
  buildUtmRelativeHref,
  UTM_CAMPAIGN,
  UTM_MEDIUM,
  UTM_SOURCE,
} from '@matchastock/routes/utm';

import { ThemeToggle } from './theme-toggle';

const MATCHASTOCK_SUPPORT_LINK = buildUtmRelativeHref(supportRoute(), {
  campaign: UTM_CAMPAIGN.matchastock_support,
  source: UTM_SOURCE.matchastock,
  medium: UTM_MEDIUM.web,
  content: 'footer_link',
  term: 'Support',
});

const MATCHASTOCK_PRIVACY_LINK = buildUtmRelativeHref(privacyRoute(), {
  campaign: UTM_CAMPAIGN.matchastock_privacy,
  source: UTM_SOURCE.matchastock,
  medium: UTM_MEDIUM.web,
  content: 'footer_link',
  term: 'Privacy',
});

const MATCHASTOCK_TERMS_LINK = buildUtmRelativeHref(termsRoute(), {
  campaign: UTM_CAMPAIGN.matchastock_terms,
  source: UTM_SOURCE.matchastock,
  medium: UTM_MEDIUM.web,
  content: 'footer_link',
  term: 'Terms of Service',
});

const RAHUL_FOOTER_LINK = buildUtmAbsoluteHref('https://www.rahrang.com', {
  campaign: UTM_CAMPAIGN.matchastock,
  source: UTM_SOURCE.matchastock,
  medium: UTM_MEDIUM.web,
  content: 'footer_link',
  term: 'Rahul',
});

const MATCHA_ESSENTIALS_LIST_LINK = buildUtmRelativeHref(
  matchaEssentialsListRoute(),
  {
    campaign: UTM_CAMPAIGN.matchastock_list,
    source: UTM_SOURCE.matchastock,
    medium: UTM_MEDIUM.web,
    content: 'footer_link',
    term: 'Kimi’s matcha essentials',
  },
);

export function AppFooter() {
  return (
    <footer className="flex flex-col items-center sm:flex-row sm:items-baseline sm:justify-between gap-4 pt-2 pb-4 px-4 sm:pt-4 sm:pb-8 sm:px-8 text-sm">
      <div className="space-y-2 text-center sm:text-left">
        <p className="text-muted-foreground">
          ✅{' '}
          <Link
            className="underline hover:text-accent-foreground-hover focus:text-accent-foreground-hover"
            to={MATCHA_ESSENTIALS_LIST_LINK}
            target="_blank"
            rel="noreferrer noopener"
          >
            Kimi’s matcha essentials
          </Link>
        </p>

        <p className="text-muted-foreground">
          Built with 😊 by{' '}
          <a
            className="underline hover:text-accent-foreground-hover focus:text-accent-foreground-hover"
            href={RAHUL_FOOTER_LINK}
            target="_blank"
            rel="noreferrer noopener"
          >
            Rahul
          </a>
        </p>

        <p className="text-muted-foreground">
          Follow{' '}
          <a
            className="underline hover:text-accent-foreground-hover focus:text-accent-foreground-hover"
            href="https://www.instagram.com/kimimatcha"
            target="_blank"
            rel="noreferrer noopener"
          >
            @kimimatcha
          </a>{' '}
          on Instagram
        </p>

        <p className="text-muted-foreground">
          Secured your matcha?{' '}
          <Link
            className="underline hover:text-accent-foreground-hover focus:text-accent-foreground-hover"
            to={MATCHASTOCK_SUPPORT_LINK}
          >
            Support us!
          </Link>
        </p>
      </div>

      <div className="space-y-2 text-center sm:text-right">
        <ThemeToggle />

        <p className="text-muted-foreground">
          <Link
            className="underline hover:text-accent-foreground-hover focus:text-accent-foreground-hover"
            to={MATCHASTOCK_PRIVACY_LINK}
          >
            Privacy Policy
          </Link>
        </p>

        <p className="text-muted-foreground">
          <Link
            className="underline hover:text-accent-foreground-hover focus:text-accent-foreground-hover"
            to={MATCHASTOCK_TERMS_LINK}
          >
            Terms of Service
          </Link>
        </p>
      </div>
    </footer>
  );
}
