import { AppHeader } from '~/components/app-header';

export default function AppPage() {
  return (
    <>
      <AppHeader isSignedIn={false} />
      <main className="flex flex-col items-center px-4 pb-4 sm:px-8 sm:pb-8 gap-y-16">
        <div className="prose dark:prose-invert">
          <h1>Thank you, and farewell</h1>
          <p>
            MatchaStock is saying goodbye. We’d like to give a big THANK YOU to
            everyone for supporting us throughout the journey -- whether you
            donated to Rahul’s fundraiser, engaged with Kimi’s content on
            TikTok, or just signed up for notifications. It’s meant so much to
            the both of us.
          </p>
          <p>
            Looking for more matcha content? Follow{' '}
            <a href="https://www.instagram.com/kimimatcha" target="_blank">
              @kimimatcha
            </a>{' '}
            on Instagram!
          </p>
        </div>
      </main>
    </>
  );
}
