import { Link, useNavigate } from '@remix-run/react';
import { MenuIcon } from 'lucide-react';

import { homeRoute, signInRoute, signUpRoute } from '@matchastock/routes';

import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { UserButton } from './clerk';

export function AppHeaderSimple() {
  return (
    <header className="flex justify-center py-2 px-4 sm:py-4 sm:px-8">
      <AppTitle />
    </header>
  );
}

export function AppTitle() {
  return (
    <h1 className="text-2xl">
      <Link
        className="hover:text-accent-foreground-hover focus:text-accent-foreground-hover"
        to={homeRoute()}
      >
        <span className="text-xl mr-1">🍵</span> MatchaStock
      </Link>
    </h1>
  );
}

const SignedOutDropdownMenu = () => {
  const navigate = useNavigate();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="sm:hidden">
        <Button
          variant="ghost"
          className="h-8 w-8"
          size="icon"
          aria-label="Toggle navigation"
        >
          <MenuIcon />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          onClick={() => {
            navigate(signInRoute());
          }}
        >
          Sign in
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            navigate(signUpRoute());
          }}
        >
          Sign up
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const SignedOutNav = () => {
  return (
    <nav className="flex-row items-baseline gap-x-4 hidden sm:flex">
      <Button variant="secondary" asChild>
        <Link to={signInRoute()}>Sign in</Link>
      </Button>
      <Button variant="default" asChild>
        <Link to={signUpRoute()}>Sign up</Link>
      </Button>
    </nav>
  );
};

export function AppHeader({ isSignedIn }: { isSignedIn: boolean }) {
  return (
    <header className="flex flex-row justify-between items-center sm:items-baseline py-2 px-4 gap-y-4 sm:py-4 sm:px-8 sm:gap-x-4 sm:gap-y-0">
      <AppTitle />

      {/* {isSignedIn ? (
        <UserButton />
      ) : (
        <>
          <SignedOutDropdownMenu />
          <SignedOutNav />
        </>
      )} */}
    </header>
  );
}
